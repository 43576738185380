import CatalogProduct from '../models/catalogProduct';
import Cart from '../models/cart';
import PaycoOrder from '../models/paycoOrder';
import PaycoSaleRow from '../models/paycoSaleRow';
import Product from '../models/product';
import Customer from '../models/customer';
import IPosDetails from '../models/posDetails';
import PaymentTerminal from '../models/paymentTerminal';
import Shortcut from '../models/shortcut';

interface ProductResponse {
  productID: number;
  productCode: string;
  productName: string;
  infoText: string;
  salePrice: number;
  departmentCode: string;
  departmentName: string;
  vatPercent: number;
  isPriceCompulsory: boolean;
}

const apiUrl = process.env.REACT_APP_BASKET_URI || '';
const imageUrl = process.env.REACT_APP_BASKET_IMAGE_URI || '';
const paycoEnv = process.env.REACT_APP_PAYCOENV || '';
const env = process.env.REACT_APP_ENV || ''; // local, dev, test, prod

/*
// const apiUrl = 'https://wscore-posmanager-hsltest.azurewebsites.net/api';
const apiUrl = 'https://wscore-posmanager-demo.azurewebsites.net/api';
// const apiUrl = 'https://localhost:44305/api';
const imageUrl = 'https://stposmanagerdemo.blob.core.windows.net/images';
// const imageUrl = 'https://stposmanagerhsltest.blob.core.windows.net/images';
const paycoEnv = '';
const env = 'local';
*/
export const getCart = async (cartId: string, token: string): Promise<Cart> => {
  const response: Cart = await (
    await fetch(`${apiUrl}/cart/${cartId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return response;
};

export const reInitializeCart = async (
  cartId: string,
  posDetails: IPosDetails,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/reinitialize`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      shopCode: posDetails.shopCode,
      posNumber: +posDetails.posCode,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const voidCart = async (
  cartId: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/voidreceipt`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const getProducts = async (
  query: string,
  shopCode: string,
  token: string
): Promise<Array<CatalogProduct>> => {
  console.log('getProducts');
  const response: Array<ProductResponse> = await (
    await fetch(`${apiUrl}/search/${shopCode}/products?query=${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  const products = response.map(
    (x) =>
      new CatalogProduct(
        x.productID,
        x.productCode,
        x.productName,
        x.infoText,
        x.salePrice,
        x.departmentCode,
        x.departmentName,
        x.vatPercent,
        x.isPriceCompulsory,
        imageUrl.length === 0 ? null : `${imageUrl}/${x.productCode}.png`
      )
  );
  return products;
};

export const getProductsByProductType = async (
  shopCode: string,
  productTypeCode: string,
  token: string
): Promise<Array<CatalogProduct>> => {
  const response: Array<ProductResponse> = await (
    await fetch(`${apiUrl}/search/${shopCode}/products/${productTypeCode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  const products = response.map(
    (x) =>
      new CatalogProduct(
        x.productID,
        x.productCode,
        x.productName,
        x.infoText,
        x.salePrice,
        x.departmentCode,
        x.departmentName,
        x.vatPercent,
        x.isPriceCompulsory,
        imageUrl.length === 0 ? null : `${imageUrl}/${x.productCode}.png`
      )
  );
  return products;
};

export const getProductDetails = async (
  productCode: string,
  shopCode: string,
  token: string
): Promise<CatalogProduct> => {
  // console.log('getProductDetails');
  const response: ProductResponse = await (
    await fetch(`${apiUrl}/search/${shopCode}/productdetails/${productCode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  let url = null;
  await fetch(`${imageUrl}/${response.productCode}.png`).then(
    (imageResponse) => {
      // console.log(imageResponse.status);
      if (imageResponse.status !== 404) {
        url = `${imageUrl}/${response.productCode}.png`;
      }
    }
  );

  return new CatalogProduct(
    response.productID,
    response.productCode,
    response.productName,
    response.infoText,
    response.salePrice,
    response.departmentCode,
    response.departmentName,
    response.vatPercent,
    response.isPriceCompulsory,
    url
    // imageUrl.length === 0 ? null : `${imageUrl}/${response.productCode}.png`
  );
};

export const getCustomers = async (
  query: string,
  shopCode: string,
  token: string
): Promise<Array<Customer>> => {
  const response: Array<Customer> = await (
    await fetch(`${apiUrl}/search/${shopCode}/customers?query=${query}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  // console.log(response);
  return response;
};

export const getShortcuts = async (
  shopCode: string,
  token: string
): Promise<Array<Shortcut>> => {
  const response: Array<Shortcut> = await (
    await fetch(`${apiUrl}/search/${shopCode}/shortcuts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  // return response;
  const shortcuts = response.map(
    (x) =>
      new Shortcut(
        x.shortcutID,
        x.shortcutGroupCode,
        x.shortcutGroupName,
        x.name,
        x.buttonColumn,
        x.buttonRow,
        x.command,
        x.parameters,
        x.background,
        x.isSelfCheckout,
        imageUrl.length === 0 ? null : `${imageUrl}/${x.parameters}.png`
      )
  );
  return shortcuts;
};

export const initializeCart = async (
  posDetails: IPosDetails,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      shopCode: posDetails.shopCode,
      posNumber: +posDetails.posCode,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addCustomer = async (
  cartId: string,
  customerCode: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/customer`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      customerCode,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const removeCustomer = async (
  cartId: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/customer`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addProductRow = async (
  cartId: string,
  productCode: string,
  quantity: number,
  token: string,
  salePrice?: number
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/products`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      productCode,
      quantity,
      salePrice,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addProductRowDiscount = async (
  cartId: string,
  rowId: string,
  token: string,
  discountAmount: number,
  discountPercent: number
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/products/discount`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      rowId,
      discountAmount,
      discountPercent,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addReceiptInfo = async (
  cartId: string,
  receiptInfo: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/receiptinfo`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      receiptInfo,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const addCashdraw = async (
  cartId: string,
  productCode: string,
  quantity: number,
  salePrice: number,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/cashdraw`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      productCode,
      quantity,
      salePrice,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const updateProductRow = async (
  cartId: string,
  rowId: string,
  quantity: number,
  productInfo: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/products`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      rowId,
      quantity,
      productInfo,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

export const deleteProductRow = async (
  cartId: string,
  rowId: string,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/products/${rowId}`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};

const generatePaycoSaleRows = (
  productRows: Array<Product>
): Array<PaycoSaleRow> => {
  const rows = new Array<PaycoSaleRow>();
  productRows.forEach((x: Product) => {
    // eslint-disable-next-line no-plusplus
    rows.push(
      new PaycoSaleRow(
        x.productName,
        x.productInfo,
        x.infoText,
        Math.round(x.totalAmountWithDiscount * 100)
      )
    );
  });
  return rows;
};

interface IPaycoData {
  cartId: string;
  customerCode?: string;
  env: string;
  deniedTenders?: string;
}

const generateDataRow = (cart: Cart): string => {
  const data: IPaycoData = {
    cartId: cart.id,
    env: paycoEnv,
  };
  const deniedTenders = '';

  if (cart.customer !== null) {
    data.customerCode = cart.customer.customerCode;
    if (cart.customer.customerTypeCode === '0') {
      data.deniedTenders = deniedTenders;
    }
  } else {
    data.deniedTenders = deniedTenders;
  }
  data.env = env;
  return JSON.stringify(data);
};

export const initializePayco = async (
  cart: Cart,
  posDetails: IPosDetails,
  token: string
): Promise<string> => {
  // map cart to payco object
  const paycoOrder = new PaycoOrder(
    '00000000-0000-0000-0000-000000000000',
    posDetails.shopCode,
    posDetails.posCode,
    cart.operator.operatorName,
    `${posDetails.shopCode}-${posDetails.posCode}`, // should be "shopcode-poscode"
    generateDataRow(cart),
    new PaymentTerminal('Poplatek', posDetails.terminalCode),
    generatePaycoSaleRows(cart.products),
    cart.disabledTenders
  );
  // post payco object to integration api
  const response = await fetch(`${apiUrl}/payco/PaycoInitialization`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(paycoOrder),
  });
  if (response.ok) {
    const url = await response.text();
    return url;
  }
  return Promise.reject(await response.json());
};

export const receiptCancellation = async (
  orderId: string,
  operatorName: string,
  token: string
): Promise<string> => {
  // post payco object to integration api
  const response = await fetch(
    `${apiUrl}/payco/PaycoCancellation?orderId=${orderId}&operatorCode=${operatorName}`,
    {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.ok) {
    const url = await response.text();
    return url;
  }
  return Promise.reject(await response.json());
};

// FIXME: Fix params
export const printReceipt = async (
  receiptData: string,
  format: string,
  posDetails: IPosDetails,
  token: string
): Promise<void> => {
  const uri = new URL(`${apiUrl}/Payco/PrintReceipt`);
  uri.searchParams.append('format', format);
  uri.searchParams.append('eject', 'true');
  uri.searchParams.append('deviceType', 'Poplatek');
  uri.searchParams.append('deviceAddress', posDetails.terminalCode);

  const response = await fetch(uri.toString(), {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(receiptData),
  });
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject();
};

export const getReceipt = async (
  cartId: string,
  format: string,
  token: string
): Promise<string> => {
  const response = await fetch(
    `${apiUrl}/cart/${cartId}/receipt/download/${format}`,
    {
      method: 'post',
      headers: {
        'Content-Type': format === 'png' ? 'image/png' : 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.ok) {
    if (format === 'png') {
      const receipt = await response.blob();
      const reader = new FileReader();
      const result = await new Promise<string>((resolve, reject) => {
        reader.readAsDataURL(receipt);
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            const base64Part = reader.result.substring(22); // data:image/png;base64, = 22 characters
            return resolve(base64Part);
          }
          return resolve('');
        };
        reader.onerror = () => reject(new Error('Failed to load blob'));
      });
      return result;
    }
    const receipt = await response.text();
    return receipt;
  }
  return Promise.reject(await response.json());
};

// Only for testing purposes !!!
export const addCashPayment = async (
  cartId: string,
  paymentCode: string,
  amount: number,
  token: string
): Promise<Cart> => {
  const response = await fetch(`${apiUrl}/cart/${cartId}/payments`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      paymentCode,
      amount,
    }),
  });
  if (response.ok) {
    const cart = await response.json();
    return cart;
  }
  return Promise.reject(await response.json());
};
